import _ from 'lodash';
import TagTypes from '../../constants/TagTypes';

const timestamp = new Date().toISOString();

const REQUIRED_FIELDS = {
  firstName: {
    $and: [
      {
        $not: ''
      }
    ]
  },
  lastName: {
    $and: [
      {
        $not: ''
      }
    ]
  },
  zipCode: {
    $and: [
      {
        $not: ''
      }
    ]
  },
  // userExpertises
  tags: {
    $tag_type_count_gte: {
      type: TagTypes.Expertise,
      value: 1
    }
  },
  inactive: false
  // $or: [
  //   {
  //     // both dates are in the future (not yet sleeping)
  //     $and: [
  //       { sleepingStartingAt: { $gt: timestamp } },
  //       { sleepingEndingAt: { $gt: timestamp } },
  //     ]
  //   },
  //   {
  //     // both dates are in the past (no longer sleeping)
  //     $and: [
  //       { sleepingStartingAt: { $lt: timestamp } },
  //       { sleepingEndingAt: { $lt: timestamp } },
  //     ]
  //   }
  // ]
}

export default REQUIRED_FIELDS;

export const REQUIRED_EDUCATOR_FIELDS = _.omit(REQUIRED_FIELDS, [
  // 'biography',
  'tags'
]);

export const REQUIRED_PARTNER_FIELDS = _.omit(REQUIRED_FIELDS, [
  // 'userSubjects',
  // 'userGrades'
]);
