import _ from 'lodash';
import { DocumentDuplicateIcon, EyeIcon } from '@heroicons/react/24/outline';
import { DialogManager } from '../../../dialogs_new/DialogManager';
import useDialogLauncher from '../../_hooks/useDialogLauncher';

export default function useActionLinks(event) {
  const launch = useDialogLauncher();

  function onChangeVisibility() {
    launch(DialogManager.EventVisibility.Config(event.id));
  }

  function onDuplicate() {
    launch(DialogManager.EventDuplicate.Config(event.id));
  }

  return _.remove([
    {
      type: 'button',
      icon: DocumentDuplicateIcon,
      label: 'Duplicate Event',
      onClick: onDuplicate,
      active: false
    },
    {
      type: 'button',
      icon: EyeIcon,
      label: event.data.published ?
        'Set Event Visibility' :
        'Set Event Visibility',
      onClick: onChangeVisibility,
      active: event.data.published,
      activeColor: 'green'
    }
  ], entry => entry);
};
