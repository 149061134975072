import { extractTextFromQuery } from '../../../utils/query-utils';

export default {
  param: extractTextFromQuery,
  query: function(text) {
    if (text) {
      return {
        $or: [
          {
            biography: {
              $ilike: `%${text}%`
            }
          },
          {
            id: {
              $includes_any_ilike: {
                'tagNodes.description': `%${text}%`
              }
            }
          },
          // {
          //   'tagNodes.description': {
          //     $ilike: `%${text}%`
          //   }
          // },
          // {
          //   'tagNodes.tag.name': {
          //     $ilike: `%${text}%`
          //   }
          // },
          {
            id: {
              $includes_any_tag_ilike: {
                'tag.name': `%${text}%`
              }
            }
          },
          // {
          //   'tags.name': {
          //     $ilike: `%${text}%`
          //   }
          // }
        ]
      }
    }
  }
}
