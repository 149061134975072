import React, { useCallback, useState } from 'react';
import { parse, stringify } from 'query-string';
import _ from 'lodash';
import DialogTemplate from '../../dialogs_common/DialogTemplate';
import CloseDialogButton from '../../dialogs_common/CloseDialogButton';
import TagTypes from '../../constants/TagTypes';
import SearchFilter from '../../forms/_fields_v2/SearchFilter';
import DisclosureTagFilter from '../../forms/_fields_v2/DisclosureTagFilter';
import SearchTags from '../../components/SearchTags';
import ClearAllFilters from '../../components/ClearAllFilters';
import { getSearchUtils } from '../../utils/query-utils';
import useQuery from '../../pages/projects/useQuery';
import useFilterCount from '../../hooks/useFilterCount';
import useStewardshipFilter from '../../pages/projects/useStewardshipFilter.object';
import useInteractionTypesFilterParam from '../../hooks/useInteractionTypesFilterParam';
import useDistanceFilterParam from '../../hooks/useDistanceFilterParam';
import REQUIRED_FIELDS_PUBLIC from '../../pages/projects/REQUIRED_FIELDS_PUBLIC';
import REQUIRED_FIELDS_PAST from '../../pages/projects/REQUIRED_FIELDS_PAST';
import SolidButton from '../../components/Button/SolidButton';
import DisclosureFilter from '../../forms/_fields_v2/DisclosureFilter';
import SolidButtonLoader from '../../components/Button/SolidButtonLoader';

export default function ProjectFiltersDialog(props) {
  const { router, showPast = false } = props;

  const { location, history } = router;

  const [search, setSearch] = useState(parse(location.search));
  const [querySearch, setQuerySearch] = useState(parse(location.search));

  const debounceSetQuerySearch = useCallback(_.debounce(setQuerySearch, 250), []);

  const stewardshipFilter = useStewardshipFilter(search);
  const interactionTypesFilter = useInteractionTypesFilterParam(search);
  const distanceFilter = useDistanceFilterParam(search);

  const filterFields = {
    title: 'title',
    name: 'name',
    school: 'school',
    activities: 'activities',
    expertiseTypes: 'expertiseTypes',
    subjects: 'subjects',
    ages: 'ages',
    participants: 'participants',
    // commitments: 'commitments'
  };

  const filterCount = useFilterCount(search, _.keys(filterFields));

  function onSubmit() {
    history.push({
      pathname: location.pathname,
      search: stringify({
        ...search,
        page: 1
      })
    });
    props.onCancel();
  }

  function onChange(nextSearch) {
    setSearch(nextSearch);
    debounceSetQuerySearch(nextSearch);
  }

  function getTagParams(tag) {
    return {
      where: {
        eager: {
          $where: {
            ...stewardshipFilter,
            ...interactionTypesFilter,
            ...distanceFilter,
            'tagNodes.tagId': tag.id,
            $and: (showPast ? REQUIRED_FIELDS_PAST : REQUIRED_FIELDS_PUBLIC).$and
          },
        }
      },
      pagination: {
        pageSize: 1
      }
    };
  }

  function getActivityParams(tag) {
    return {
      where: {
        eager: {
          $where: {
            ...stewardshipFilter,
            ...interactionTypesFilter,
            ...distanceFilter,
            activityId: tag.id,
            $and: (showPast ? REQUIRED_FIELDS_PAST : REQUIRED_FIELDS_PUBLIC).$and
          },
        }
      },
      pagination: {
        pageSize: 1
      }
    };
  }

  const searchUtils = getSearchUtils(search, onChange);

  const result = useQuery({
    search: querySearch,
    showPast
  });

  return (
    <DialogTemplate className="w-screen max-w-screen-sm h-[calc(100dvh-32px)] max-h-[calc(720px)]">
      <CloseDialogButton onCancel={props.onCancel} />
      <div className="flex flex-col space-y-8x h-full">
        <div className="space-y-1 p-6 border-b border-cs-gray-200">
          <h2 className="text-cs-gray-900 text-2xl font-semibold">
            Filters
          </h2>
          <div className="text-cs-gray-500 text-sm">
            Narrow your search results.
          </div>
          {/*<div className="pt-4">*/}
          {/*  <SearchTags*/}
          {/*    search={search}*/}
          {/*    onChange={setSearch}*/}
          {/*  />*/}
          {/*</div>*/}
        </div>
        <div className="px-6 py-8 space-y-8 overflow-y-auto h-full">
          <div className="space-y-4">
            <DisclosureFilter
              label="Search"
              value={[
                searchUtils(filterFields.title).value,
                searchUtils(filterFields.name).value,
                searchUtils(filterFields.school).value
              ]}
            >
              <SearchFilter
                label="Project title"
                description="Find projects with a title that contains the phrase you type."
                value={searchUtils(filterFields.title).value}
                onChange={searchUtils(filterFields.title).onChangeValue}
              />
              <SearchFilter
                label="Educator name"
                description="Find projects created by an educator whose first or last name matches one of the words you enter."
                value={searchUtils(filterFields.name).value}
                onChange={searchUtils(filterFields.name).onChangeValue}
              />
              <SearchFilter
                label="School"
                description="Find projects affiliated with a school whose name contains the phrase you type."
                value={searchUtils(filterFields.school).value}
                onChange={searchUtils(filterFields.school).onChangeValue}
              />
            </DisclosureFilter>
          </div>
          <DisclosureTagFilter
            modelName="project"
            type={TagTypes.Activity}
            label="Activity"
            getParams={getActivityParams}
            value={searchUtils(filterFields.activities).value}
            onChange={searchUtils(filterFields.activities).onChangeArray}
          />
          <DisclosureTagFilter
            modelName="project"
            type={TagTypes.ExpertiseType}
            label="Skills and experience"
            getParams={getTagParams}
            value={searchUtils(filterFields.expertiseTypes).value}
            onChange={searchUtils(filterFields.expertiseTypes).onChangeArray}
          />
          <DisclosureTagFilter
            modelName="project"
            type={TagTypes.Subject}
            label="Subject areas"
            getParams={getTagParams}
            value={searchUtils(filterFields.subjects).value}
            onChange={searchUtils(filterFields.subjects).onChangeArray}
          />
          <DisclosureTagFilter
            modelName="project"
            type={TagTypes.Age}
            label="Age group"
            getParams={getTagParams}
            value={searchUtils(filterFields.ages).value}
            onChange={searchUtils(filterFields.ages).onChangeArray}
          />
          <DisclosureTagFilter
            modelName="project"
            type={TagTypes.Participant}
            label="Participants"
            getParams={getTagParams}
            value={searchUtils(filterFields.participants).value}
            onChange={searchUtils(filterFields.participants).onChangeArray}
          />
          {/*<DisclosureTagFilter*/}
          {/*  modelName="project"*/}
          {/*  type={TagTypes.Commitment}*/}
          {/*  label="Commitment"*/}
          {/*  getParams={getTagParams}*/}
          {/*  value={searchUtils(filterFields.commitments).value}*/}
          {/*  onChange={searchUtils(filterFields.commitments).onChangeArray}*/}
          {/*/>*/}
        </div>
        <div className="p-6 flex items-center justify-between border-t border-cs-gray-200">
          <ClearAllFilters
            show={true}
            className="hover:opacity-70"
            onClick={() => setSearch(_.omit(search, _.keys(filterFields)))}
            count={filterCount}
          />
          {result._isPreviousData ? (
            <SolidButton onClick={onSubmit}>
              <SolidButtonLoader />
            </SolidButton>
          ) : (
            <SolidButton onClick={onSubmit}>
              Show {result.meta?.totalCount} results
            </SolidButton>
          )}
        </div>
      </div>
    </DialogTemplate>
  );
};
